.main-content-container {
  display: flex;

  .sidecontent {
    width: 500px;
    height: 100vh !important;
    overflow: auto;
    border-right: 4px solid #fff;
    padding-bottom: 6rem;
 
  }
  .main-dashboard {
    font-family: "Montserrat-Regular";
    line-height: 25px;
    width: 70%;

    .count-container {
      flex-direction: row;
      gap: 2rem;

      .count-div {
        border-radius: 14px;
        width: 300px;
        padding: 1rem;
        padding-top: 1.5rem;

        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        background-color: #fafafa;

        h3,
        h4 {
          margin: 0;
        }

        h4 {
          color: #332685;
        }

        .count-heading {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h3 {
            font-size: 18px;
            font-family: "Montserrat-Medium";
          }
          .icon-container {
            display: flex;
            align-content: center;
            justify-content: center;
            background-color: #dddada;
            padding: 0.5rem;
            border-radius: 14px;
          }
        }

        &:hover {
          cursor: pointer;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
    }

    .charts {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin: 3rem 0;
      margin-top: 4rem;
      .line-chart {
        width: 65%;
        background-color: #f4f4f4;
        border-radius: 13px;
        padding: 0.5rem;

        .select-box {
          position: relative;
          right: 0;
          justify-content: space-between;
          align-items: center;
          margin: 1rem 0.4rem;
        }
      }

      .doughnut-chart {
        width: 35%;
        background-color: #f4f4f4;
        border-radius: 13px;
        padding: 0.5rem;

        .select-box {
          position: relative;
          right: 0;
          justify-content: space-between;
          align-items: center;
          margin: 1rem 0.4rem;
        }
      }
    }
    .no-margin-p {
      margin: 0;
    }

    .flex {
      display: flex;
      justify-content: space-between;
    }
  }

  .info-box {
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12);
    margin: 15px;
  }

  .info-box-unread {
    background: #e1e9f5;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12);
    margin: 15px;
  }

  .header,
  .body,
  .footer {
    display: flex;
    align-items: center;
  }

  .header {
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .icon {
    margin-right: 8px;
    color: #007bff;
    font-size: 12px;
  }

  .details {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .code-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    font-size: 12px;
  }

  .policy {
    margin-left: 8px;
    font-weight: bold;
    font-size: 14px;
  }

  .timestamp {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 11px;
  }

  hr {
    border: 0;
    border-top: 1px solid #ddd;
    margin: 8px 0;
  }

  .body {
    display: flex;
    justify-content: space-between;
  }

  .info-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .label {
    font-weight: bold;
    margin-right: 4px;
    font-size: 10px;
  }

  .value {
    color: #333;
    font-size: 10px;
  }

  a.value {
    color: #007bff;
    text-decoration: none;
  }

  .footer {
    justify-content: flex-start;
    margin-top: 8px;
  }

  .integration-link {
    color: #ff9800;
    text-decoration: none;
    font-weight: bold;
  }

  .react-tabs__tab-list {
    background-color: #fff;
  }

  .react-tabs__tab--selected {
    border: none;
    border-bottom: 3px solid #d52429;
  }

  .react-tabs__tab:focus {
    outline: none;
    border-bottom: 3px solid #d52429 !important;
  }

  .react-tabs__tab:focus:after {
    background-color: none;
    background: none;
  }

  .search-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;

    input {
      width: 300px;
      padding: 10px;
      border: none;
      border-radius: 4px 0 0 4px;
      font-size: 16px;
    }

    .filter-button {
      background-color: #14444a;
      border: none;
      border-radius: 0 4px 4px 0;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      padding: 10px 15px;
      color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

.case-info-box {
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.12);
  margin: 15px;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left-section,
  .right-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .left-section {
    border-right: 1px solid #ccc;
    margin-right: 10px;
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .icon {
        margin-right: 8px;
        color: #f44336;
        font-size: 20px;
      }

      .details {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        border-bottom: 1px solid #ccc;
        margin-right: 10px;

        .code-link {
          color: #f57c00;
          text-decoration: none;
          font-weight: bold;
          font-size: 14px;
        }

        .policy {
          font-weight: bold;
          font-size: 14px;
        }

        .date-time {
          font-size: 12px;
          color: #007bff;
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      margin-top: 8px;

      .info-row {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .label {
          font-weight: bold;
          margin-right: 4px;
          font-size: 12px;
        }

        .value {
          color: #333;
          font-size: 12px;
        }

        .value-icon {
          margin-right: 4px;
          color: #007bff;
          font-size: 14px;
        }

        .operations-link {
          color: #ff9800;
          text-decoration: none;
          font-weight: bold;
        }
      }
    }
  }

  .right-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .time-ago {
      color: #ff9800;
      font-size: 12px;
      margin-bottom: 8px;
    }

    .service-info {
      display: flex;
      flex-direction: column;

      .service-header {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .icon {
          margin-right: 8px;
          color: #007bff;
          font-size: 20px;
        }

        .service-name {
          font-weight: bold;
          font-size: 14px;
          margin-right: 4px;
        }

        .service-date {
          font-size: 12px;
          color: #007bff;
        }

        .service-status {
          font-size: 12px;
          color: #999;
          margin-left: 4px;
        }
      }

      .progress {
        display: flex;
        align-items: center;
        font-size: 12px;

        .step {
          padding: 4px 8px;
          border-radius: 4px;
          margin-right: 4px;
          background-color: #f0f0f0;

          &.active {
            background-color: #007bff;
            color: #fff;
          }
        }
      }
    }
  }
}

.payment-status-div {
  background-color: #fee3e4;
  color: #d55558;
  margin-bottom: 0;
  margin-left: auto;
  padding: 0.5rem;
  border-radius: 6px;
  p {
    font-size: 10px;
    font-weight: 600;
  }
}

.payment-status-div-paid {
  background-color: #e9f6f2;
  color: #1ca493;

  margin-bottom: 0;
  margin-left: auto;
  padding: 0.5rem;
  border-radius: 6px;

  p {
    font-size: 10px;
    font-weight: 600;
  }
}

.loading-input-text{
  position: absolute;
  left: 20rem;
  margin-bottom: 0;
  color: rgb(213, 36, 41);
  font-size: 14px;
}

.tabStyle{
  font-size: 14px;
}

.main-content-container .react-tabs__tab-list{
  display: flex;
  overflow-x: scroll;
 padding: 1rem 0 0 0 ;
}

.react-tabs__tab{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 14px;
  font-weight: 500;
}

